/* GENERAL STYLES
---------------------------------------------*/
html,
body,
form {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font: 16px/1.5em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  background: #E3E6E6;
}
a {
  color: #041E42;
  text-decoration: underline;
  outline: none;
  transition: color 250ms ease, background 500ms ease;
}
a:hover {
  color: #666;
  text-decoration: underline;
}
a img {
  border: none;
}
h1,
h2,
h3,
h4,
h5,
.PageTitleHeader,
.PageSubTitleHeader {
  font-weight: bold;
  margin: 0;
  padding: 0;
}
h1,
.PageTitleHeader {
  font-size: 2.429em;
  line-height: 1.25em;
}
h2,
.PageSubTitleHeader {
  font-size: 1.714em;
  line-height: 1.25em;
}
h3 {
  font-size: 1.429em;
  line-height: 1.25em;
}
h4 {
  font-size: 1.143em;
  line-height: 1.25em;
}
h5 {
  font-size: 0.929em;
  line-height: 1.25em;
}
p {
  margin: 0.5em 0 1.5em;
  padding: 0;
}
#PageTitle,
.PageTitleHeader {
  display: none;
}
.left {
  float: left;
}
.right {
  float: right;
}
.center {
  text-align: center;
}
.textalignright {
  text-align: right;
}
.textalignleft {
  text-align: left;
}
hr {
  height: 1px;
  line-height: 1px;
  margin: 2em 0;
  clear: both;
  border: none;
  background: #E3E6E6;
  color: #E3E6E6;
}
img.left {
  float: left;
  margin: 0 7px 1px 0;
}
img.right {
  float: right;
  margin: 0 0 7px 7px;
}
img.outline {
  border: 4px solid #666;
}
.clearing {
  clear: both;
  height: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
  margin-top: -7px;
}
.clearBoth {
  clear: both;
}
.clearRight {
  clear: right;
}
.clearLeft {
  clear: left;
}
.invisibleButAccessible {
  display: none;
}
.idbmsBreadcrumbSeparator {
  color: green !important;
}
.expand img {
  background-color: green !important;
}
.expand {
  background-color: green !important;
  display: inline-block;
  width: 18px;
}
.Error,
.error {
  width: auto!important;
}
img[src="/images/icons/v2/common/brick_edit.gif"],
img[src="/images/icons/v2/common/delete.gif"],
img[src="/images/icons/v2/common/arrow_out.gif"],
img[src="/images/e.gif"],
img[src="/images/spacer.gif"],
img[src="/images/icons/v2/bomb.png"] {
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  float: none!important;
  width: 16px!important;
  height: 16px !important;
}
#inner-wrap img {
  max-width: 100%;
  height: auto;
}
#inner-wrap img[src="/images/spacer.gif"] {
  display: none;
}
#inner-wrap img * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
}
.EventsHeader td:nth-child(2) {
  width: 18px !important;
  height: 18px !important;
}
.EventsHeader span.expand img {
  width: 18px !important;
  height: 18px !important;
}
::selection {
  background: blue;
  color: #fff;
}
/* Safari 3.1+, Opera 9.5+, Chrome 2+ */
::-moz-selection {
  background: blue;
  color: #fff;
}
/* Mozilla Firefox */
/* Flexbox Equal heights */
.flexbox .equal-height {
  display: flex;
  flex-direction: row;
}
.flexbox .equal-height-item {
  display: inherit;
  flex-direction: column;
}
.flexbox .equal-height-item-inner {
  flex: 1 0 auto;
}
/* Stack items on mobile */
@media only screen and (max-width:  699px ) {
  .flexbox .equal-height {
    flex-direction: column;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .container {
    width: 100%;
  }
}
.button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  background: #fff;
  border: 2px solid #000;
  padding: 15px 30px;
  color: #000;
  font-size: 1.25em;
  transition: all 0.2s ease;
}
.button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover {
  background: #041E42;
  border: 2px solid #041E42;
  color: #fff;
}
.button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  background: #041E42;
  border: 2px solid #041E42;
  color: #fff;
}
/* UTILITY BAR ELEMENTS
---------------------------------------------*/
.utilityBar {
  float: left;
  width: 100%;
  background: #000;
  padding: 1em 0;
}
.memberTools {
  float: right;
  margin-right: 20px;
}
.memberTools ul {
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
}
.memberTools ul li {
  float: left;
  padding: 0 0 0 20px;
}
.memberTools ul li a {
  display: block;
  color: #fff;
}
.search {
  float: right;
  min-width: 50px;
}
.search img[id$="_imgbtnSearch"] {
  width: 14px;
}
.search label,
.search .SearchModuleSpacer {
  display: none;
}
/* HEAD ELEMENTS
---------------------------------------------*/
.structHead {
  float: left;
  width: 100%;
  background: #041E42;
  padding: 1em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.structHead .flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.structHead .headerText {
  color: #fff;
  font-size: 2em;
  font-weight: normal;
  padding-top: 10px;
}
@media only screen and (max-width:  699px ) {
  .structHead .container {
    margin: 0;
  }
}
.headLogo {
  position: relative;
  padding: 12px 15px 12px 15px;
}
.headLogo:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background: #fff;
}
/* MAIN TOP HORIZONTAL NAV
---------------------------------------------*/
.desktopNav {
  background: #E3E6E6;
  float: left;
  width: 100%;
  position: relative;
  z-index: 99;
}
.desktopNav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.desktopNav ul li {
  float: left;
  padding: 0;
  line-height: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.desktopNav ul li:hover > a {
  color: #fff;
  background: #666;
}
.desktopNav ul li a {
  display: block;
  text-decoration: none;
  padding: 0 1em;
}
.desktopNav ul li a:hover {
  color: #fff;
  background: #666;
}
.desktopNav li.sfHover {
  position: static;
}
.desktopNav li.sfHover ul {
  left: auto;
  opacity: 1;
}
.desktopNav li.sfHover ul ul {
  left: -999em;
}
.desktopNav li.sfHover ul ul ul {
  left: -999em;
}
.desktopNav li ul {
  position: absolute;
  left: -999em;
  margin: 0px;
  background: #666;
  float: none;
  width: 15em;
  text-align: left;
  opacity: 0;
  transition: opacity 500ms ease;
  top: 100%;
}
.desktopNav li ul li {
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  line-height: normal;
  text-transform: none;
  font-size: 1em;
}
.desktopNav li ul li.sfHover {
  position: static;
}
.desktopNav li ul li.sfHover ul {
  left: auto;
  opacity: 1;
}
.desktopNav li ul li:hover a {
  background: red;
}
.desktopNav li ul li a {
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
}
.desktopNav li ul li ul {
  margin: -23px 0 0 150px;
  background: green;
  color: #fff;
  width: 170px;
}
.desktopNav li ul li ul li {
  width: 170px;
}
.desktopNav li ul li ul li.sfHover {
  position: static;
}
.desktopNav li ul li ul li.sfHover ul {
  left: auto;
  opacity: 1;
}
.desktopNav li ul li ul li a {
  color: #fff;
}
.desktopNav li ul li ul li a:hover {
  color: #E3E6E6;
}
.desktopNav li ul li ul a {
  font-weight: normal;
  text-align: left;
  padding: 0.25em 1em;
  border: none;
}
/* Flexbox styles */
.flexbox .desktopNavInner > ul {
  display: flex;
}
.flexbox .desktopNavInner > ul > li {
  float: none;
  display: inherit;
  flex-direction: column;
}
.flexbox .desktopNavInner > ul > li > a {
  display: inherit;
  width: 100%;
  line-height: 1.25em;
  padding: 20px 15px;
  text-align: center;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
}
/* BREADCRUMBS
-----------------------------------------------*/
.structBody .breadCrumbs {
  margin-bottom: 2em;
}
.structBody .breadCrumbs ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.structBody .breadCrumbs ul li {
  padding: 0 0 0 0;
  margin: 0;
  display: inline;
  font-size: .8em;
}
.structBody .breadCrumbs ul li:before {
  content: "»";
}
.structBody .breadCrumbs ul li:first-child:before {
  content: "";
}
.structBody .breadCrumbs ul li:first-child a {
  padding-left: 0;
}
.structBody .breadCrumbs ul li.selected a {
  font-weight: bold;
}
.structBody .breadCrumbs ul li a {
  text-decoration: none;
  padding: 0 5px 0 8px;
}
/* BODY ELEMENTS
---------------------------------------------*/
.structBody {
  float: left;
  padding: 40px 0 60px 0;
  width: 100%;
  background: #E3E6E6;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .structBody .mobPadding {
    padding: 0;
  }
}
@media only screen and (max-width:  699px ) {
  .structBody .mobPadding {
    padding: 0;
  }
}
.midWrap {
  padding-bottom: 3em;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .midWrap {
    padding-bottom: 1em;
  }
}
.pageName h1 {
  margin: 0 0 10px;
}
.heroImageWrapper {
  clear: both;
  float: left;
  width: 100%;
  background-size: cover;
  background-position: center top;
  position: relative;
  display: flex;
  align-items: center;
}
.heroImageWrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.37);
}
.heroImageWrapper .flexHero {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.heroImageWrapper .heroText {
  color: #fff;
  font-size: 1.525em;
  line-height: 1.25;
  padding: 60px 0;
  text-shadow: 1px 1px 2px #000;
}
@media only screen and (max-width:  699px ) {
  .heroImageWrapper .heroText {
    font-size: 1.325em;
    padding: 30px 0;
  }
}
/* NEWS LISTINGS
-----------------------------------------------*/
.newsItem {
  position: relative;
  padding: 30px 0;
  border-bottom: 1px solid #E3E6E6;
  float: left;
  width: 100%;
  clear: both;
}
.newsItem .thumb {
  position: relative;
  width: 120px;
  float: left;
}
.newsItem .text {
  padding-left: 140px;
}
.newsItem .title {
  font-size: 1.2em;
  line-height: normal;
  padding: 0 0 5px;
  font-weight: bold;
}
.gridWrap {
  margin-bottom: 50px;
  overflow: hidden;
}
@media only screen and (max-width:  699px ) {
  .gridWrap {
    margin: 0;
  }
}
.gridWrap .gridItem .thumb {
  margin: 0 0 20px;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.gridWrap .gridItem .thumb a {
  display: block;
}
.gridWrap .gridItem .thumb img.invisible {
  visibility: hidden;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .gridWrap .gridItem .thumb {
    width: 300px;
    float: left;
  }
}
@media only screen and (max-width:  699px ) {
  .gridWrap .gridItem .thumb {
    width: 400px;
    margin: 0 auto;
    max-width: 100%;
  }
}
.gridWrap .gridItem .text {
  clear: both;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .gridWrap .gridItem .text {
    padding-left: 320px;
    clear: right;
    padding-right: 20px;
    padding-top: 20px;
  }
}
@media only screen and (max-width:  699px ) {
  .gridWrap .gridItem .text {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
.gridWrap .gridItem .title {
  display: block;
  font-size: 1.2em;
  line-height: normal;
  font-weight: bold;
  padding: 0 0 5px;
}
/* EVENT LISTINGS
-----------------------------------------------*/
.eventItem {
  position: relative;
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid #E3E6E6;
}
.eventItem .date {
  position: relative;
  width: 60px;
  background: #fff;
  border: 1px solid #E3E6E6;
  text-align: center;
  float: left;
}
.eventItem .date .month {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.85em;
  line-height: 1em;
  background: #E3E6E6;
  padding: 5px 0;
}
.eventItem .date .day {
  font-size: 2em;
  line-height: 1.5em;
}
.eventItem .text {
  padding-left: 80px;
}
.eventItem .title {
  font-size: 1.2em;
  line-height: normal;
  padding: 0 0 5px;
  font-weight: bold;
}
/* FEATURE ROTATOR
---------------------------------------------*/
.imageRotator {
  margin-top: 1em;
  margin-bottom: 2em;
  /* Pager */
  /* Controls */
}
.imageRotator .bx-wrapper {
  position: relative;
}
.imageRotator .bx-wrapper .bx_slide {
  position: relative;
  /* Image */
  /* Text */
}
.imageRotator .bx-wrapper .bx_slide .sldimg {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 35%;
  height: 0;
}
.imageRotator .bx-wrapper .bx_slide .sldimg a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.imageRotator .bx-wrapper .bx_slide .sldtxt {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 40%;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 20px;
}
@media only screen and (max-width:  699px ) {
  .imageRotator .bx-wrapper .bx_slide .sldtxt {
    position: static;
    background-color: #000;
    width: 100%;
  }
}
.imageRotator .bx-wrapper .bx_slide .sldtxt .title {
  font-size: 1.25em;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 0 10px;
}
.imageRotator .bx-pager {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}
@media only screen and (max-width:  699px ) {
  .imageRotator .bx-pager {
    position: static;
    width: 100%;
    text-align: center;
    padding: 10px 0 0;
  }
}
.imageRotator .bx-pager .bx-pager-item {
  display: inline-block;
  padding-left: 12px;
}
@media only screen and (max-width:  699px ) {
  .imageRotator .bx-pager .bx-pager-item {
    padding: 0 5px;
  }
}
.imageRotator .bx-pager a {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  text-indent: -999em;
  outline: none;
}
@media only screen and (max-width:  699px ) {
  .imageRotator .bx-pager a {
    background-color: #E3E6E6;
    width: 12px;
    height: 12px;
  }
}
.imageRotator .bx-pager a.active {
  background: #000;
}
.imageRotator .bx-controls-direction a {
  position: absolute;
  top: 45%;
  z-index: 100;
  outline: none;
}
.imageRotator .bx-controls-direction a.bx-prev {
  left: 20px;
}
.imageRotator .bx-controls-direction a.bx-next {
  right: 20px;
}
/* LEFT COLUMN
---------------------------------------------*/
.secondaryNav > ul {
  margin-bottom: 30px;
}
.secondaryNav > ul > li > ul > li a {
  display: block;
  padding: 5px 5px 5px 30px;
}
.secondaryNav > ul > li > a {
  padding: 5px;
}
.secondaryNav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.secondaryNav ul li a {
  display: block;
  line-height: normal;
}
/* RIGHT CONTENT 
---------------------------------------------*/
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .structRight {
    margin-bottom: 2em;
  }
}
/* FOOTER ELEMENTS
---------------------------------------------*/
footer {
  float: left;
  width: 100%;
  color: #fff;
  padding: 2em 0;
  background: #041E42;
}
footer a {
  color: #fff;
}
footer a:hover {
  color: #E3E6E6;
}
footer .footerCopy {
  text-align: center;
  font-size: 0.875em;
}
footer .footerCopy p {
  margin: 0;
}
footer .footerCopy:after {
  content: "";
  max-width: 450px;
  background: #fff;
  height: 1px;
  width: 75%;
  margin: 30px auto;
  display: block;
}
footer .footerCopyMid {
  text-align: center;
  font-size: 0.875em;
  margin-bottom: 20px;
}
footer .footerCopyMid p {
  margin: 0;
}
footer .footerLinks ul {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  text-align: center;
}
footer .footerLinks ul li {
  display: inline-block;
  position: relative;
  padding: 0 10px;
}
footer .footerLinks ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 0.875em;
}
footer .footerLinks ul li:after {
  content: "|";
  position: absolute;
  right: -5px;
}
footer .footerLinks ul li:last-child:after {
  content: "";
}
footer .footLogo {
  text-align: center;
  padding-top: 15px;
}
/* MOBILE NAVIGATION
-----------------------------------------------*/
.m-pikabu-sidebar {
  box-shadow: inset 1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.3);
}
.m-pikabu-sidebar .mainNav ul li {
  position: relative;
}
.m-pikabu-sidebar .mainNav ul li.has-subnav .accordion-btn {
  color: #fff;
}
.m-pikabu-sidebar .mainNav ul li.selected a {
  color: #fff;
}
.m-pikabu-sidebar .mainNav ul li a {
  padding: 12px 1em;
}
.mobileSearch {
  margin: 1em;
  text-align: center;
  color: #E3E6E6;
  position: relative;
}
.mobileSearch input {
  background: #666;
  border: none;
  padding: 5px 20px 4px 10px;
  width: 100%;
  border-radius: 20px;
  color: #E3E6E6;
}
.mobileSearch img[id$="_imgbtnSearch"] {
  width: 14px;
  position: absolute;
  right: 10px;
  top: 7px;
}
.mobileSearch button,
.mobileSearch label,
.mobileSearch .SearchModuleSpacer {
  display: none;
}
#nav {
  overflow-y: scroll;
}
#nav ul li.selected > ul {
  display: block;
}
#nav ul li ul {
  display: none;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  #nav {
    display: block;
  }
}
@media only screen and (max-width:  699px ) {
  #nav {
    display: block;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .m-pikabu-sidebar,
  .m-pikabu-right-visible .m-pikabu-right,
  .m-pikabu-left-visible .m-pikabu-left {
    width: 30% !important;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .m-pikabu-left-visible .m-pikabu-container {
    -webkit-transform: translate3d(30%, 0, 0);
    -moz-transform: translate3d(30%, 0, 0);
    -ms-transform: translate3d(30%, 0, 0);
    -o-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .m-pikabu-right-visible .m-pikabu-container {
    -webkit-transform: translate3d(-30%, 0, 0);
    -moz-transform: translate3d(-30%, 0, 0);
    -ms-transform: translate3d(-30%, 0, 0);
    -o-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
.accordion-btn-wrap:focus {
  outline: 1px dotted #fff;
}
/* FORM STYLING 
---------------------------------------------*/
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.breadcrumbPh {
  background: #041E42;
  color: #fff;
  padding: 15px;
}
.breadcrumbPh div[id*="pnlBreadcrumbs"],
.breadcrumbPh div.idbmsBreadcrumbWrapper {
  display: block;
  text-align: center;
  padding: 0;
}
.breadcrumbPh div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbSeparator,
.breadcrumbPh div.idbmsBreadcrumbWrapper .idbmsBreadcrumbSeparator {
  display: none;
}
.breadcrumbPh div[id*="pnlBreadcrumbs"] > span,
.breadcrumbPh div.idbmsBreadcrumbWrapper > span {
  padding: 0 10px;
  position: relative;
}
.breadcrumbPh div[id*="pnlBreadcrumbs"] > span:before,
.breadcrumbPh div.idbmsBreadcrumbWrapper > span:before {
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
  right: -5px;
}
.breadcrumbPh div[id*="pnlBreadcrumbs"] > span:last-child:before,
.breadcrumbPh div.idbmsBreadcrumbWrapper > span:last-child:before {
  content: "";
}
.breadcrumbPh div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive,
.breadcrumbPh div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive {
  font-weight: bold;
  position: relative;
  z-index: 10;
}
.breadcrumbPh div[id*="pnlBreadcrumbs"] .idbmsBreadcrumbActive:after,
.breadcrumbPh div.idbmsBreadcrumbWrapper .idbmsBreadcrumbActive:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #041E42;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 3px;
  z-index: -1;
}
#ContentMiddle {
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 20px;
}
#ContentMiddle div[id$="_pnlMain"] {
  padding: 20px 0;
}
#ContentMiddle .imod_formItemWrapper .EventsHeader,
#ContentMiddle .imod_fieldWrapper .EventsHeader {
  background: #041E42;
  color: #fff;
  border-color: #c8c8c8;
  font-size: 1.25em;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 14px 20px 10px;
  text-transform: uppercase;
  position: relative;
}
#ContentMiddle .imod_formItemWrapper .EventsHeader a,
#ContentMiddle .imod_fieldWrapper .EventsHeader a {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  color: #fff;
}
#ContentMiddle .imod_formItemWrapper .EventsHeader a .categoryToggle,
#ContentMiddle .imod_fieldWrapper .EventsHeader a .categoryToggle {
  display: block;
  float: right;
  padding: 7px 15px;
}
#ContentMiddle .imod_fieldWrapper.donationAmount {
  display: none;
}
#ContentMiddle .imod_fieldWrapper .imod_fieldInput {
  position: relative;
}
#ContentMiddle .imod_fieldWrapper .imod_fieldInput textarea,
#ContentMiddle .imod_fieldWrapper .imod_fieldInput input[type="text"],
#ContentMiddle .imod_fieldWrapper .imod_fieldInput select {
  background: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: none;
  filter: none;
  padding: 0px 10px;
  width: 300px;
  max-width: 100%;
}
#ContentMiddle .imod_fieldWrapper .imod_fieldInput textarea:focus,
#ContentMiddle .imod_fieldWrapper .imod_fieldInput input[type="text"]:focus,
#ContentMiddle .imod_fieldWrapper .imod_fieldInput select:focus {
  border-color: #00B5E2;
  outline: none!important;
}
#ContentMiddle .imod_fieldWrapper .imod_fieldInput input[type="text"] {
  height: 50px;
}
#ContentMiddle .imod_fieldWrapper .imod_fieldInput select {
  background: #ffffff;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
#ContentMiddle .imod_fieldWrapper .imod_fieldInput .select-wrapper {
  position: relative;
}
#ContentMiddle .imod_fieldWrapper .imod_fieldInput .select-wrapper:after {
  font-family: FontAwesome;
  content: '\f107';
  font-size: 28px;
  position: absolute;
  top: 12px;
  right: 20px;
  color: #000;
  pointer-events: none;
}
#ContentMiddle .imod_fieldWrapper .imod_fieldInput select::-ms-expand {
  display: none;
}
#ContentMiddle .imod_fieldWrapper .imod_fieldInput .divAdvancedDesignations input[type="text"] {
  width: auto;
}
#ContentMiddle .imod_fieldWrapper .imod_col2 {
  float: left;
  width: 70%;
}
@media only screen and (max-width:  699px ) {
  #ContentMiddle .imod_fieldWrapper .imod_col2 {
    width: 100%;
  }
}
#ContentMiddle .imod_fieldWrapper .imod_col2 textarea,
#ContentMiddle .imod_fieldWrapper .imod_col2 input[type="text"],
#ContentMiddle .imod_fieldWrapper .imod_col2 select {
  background: #eee;
  border: 2px solid #ddd;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  padding: 0px 10px;
  width: 300px;
  max-width: 90%;
}
#ContentMiddle .imod_fieldWrapper .imod_col2 textarea input[type="text"],
#ContentMiddle .imod_fieldWrapper .imod_col2 input[type="text"] input[type="text"],
#ContentMiddle .imod_fieldWrapper .imod_col2 select input[type="text"] {
  height: 50px;
}
#ContentMiddle .imod_fieldWrapper .imod_col2 textarea select,
#ContentMiddle .imod_fieldWrapper .imod_col2 input[type="text"] select,
#ContentMiddle .imod_fieldWrapper .imod_col2 select select {
  min-height: 50px;
}
#ContentMiddle .imod_fieldWrapper .imod_col3 {
  float: left;
  width: 70%;
}
@media only screen and (max-width:  699px ) {
  #ContentMiddle .imod_fieldWrapper .imod_col3 {
    width: 100%;
  }
}
#ContentMiddle .imod_fieldWrapper .imod_col3 textarea,
#ContentMiddle .imod_fieldWrapper .imod_col3 input[type="text"],
#ContentMiddle .imod_fieldWrapper .imod_col3 select {
  background: #eee;
  border: 2px solid #ddd;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  padding: 0px 10px;
  width: 300px;
  max-width: 90%;
}
#ContentMiddle .imod_fieldWrapper .imod_col3 textarea input[type="text"],
#ContentMiddle .imod_fieldWrapper .imod_col3 input[type="text"] input[type="text"],
#ContentMiddle .imod_fieldWrapper .imod_col3 select input[type="text"] {
  height: 42px;
}
#ContentMiddle .imod_fieldWrapper .imod_col3 textarea select,
#ContentMiddle .imod_fieldWrapper .imod_col3 input[type="text"] select,
#ContentMiddle .imod_fieldWrapper .imod_col3 select select {
  min-height: 42px;
}
@media only screen and (max-width:  699px ) {
  #ContentMiddle .imod_fieldWrapper .imod_col4 {
    width: 100%;
  }
}
#ContentMiddle .imod_fieldWrapper .imod_col4 textarea,
#ContentMiddle .imod_fieldWrapper .imod_col4 input[type="text"],
#ContentMiddle .imod_fieldWrapper .imod_col4 select {
  background: #eee;
  border: 2px solid #ddd;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  padding: 0px 10px;
  width: 300px;
  max-width: 90%;
}
#ContentMiddle .imod_fieldWrapper .imod_col4 textarea input[type="text"],
#ContentMiddle .imod_fieldWrapper .imod_col4 input[type="text"] input[type="text"],
#ContentMiddle .imod_fieldWrapper .imod_col4 select input[type="text"] {
  height: 42px;
}
#ContentMiddle .imod_fieldWrapper .imod_col4 textarea select,
#ContentMiddle .imod_fieldWrapper .imod_col4 input[type="text"] select,
#ContentMiddle .imod_fieldWrapper .imod_col4 select select {
  min-height: 42px;
}
#ContentMiddle .imod_fieldWrapper.giftAmountBox {
  background: #e1e1e1;
  border: 1px solid #c8c8c8;
  font-size: 20px;
  padding: 20px;
  margin-bottom: 30px;
}
#ContentMiddle .imod_fieldWrapper.giftAmountBox .imod_fieldLabel {
  line-height: 60px;
  font-family: "Zilla Slab", Georgia, Helvetica, Arial, sans-serif;
  width: auto;
  padding-right: 20px;
}
@media only screen and (max-width:  699px ) {
  #ContentMiddle .imod_fieldWrapper.giftAmountBox .imod_fieldLabel {
    width: 100%;
    padding: 0 0 10px;
    line-height: normal;
  }
}
#ContentMiddle .imod_fieldWrapper.giftAmountBox .imod_fieldInput {
  width: auto;
}
#ContentMiddle .imod_fieldWrapper.giftAmountBox .imod_fieldInput input[type="text"] {
  background: #fff;
  height: 60px;
  line-height: 1em;
  font-size: 35px;
  font-weight: bold;
}
@media only screen and (max-width:  699px ) {
  #ContentMiddle .imod_fieldWrapper.giftAmountBox .imod_fieldInput {
    width: 100%;
  }
}
#ContentMiddle .imod_fieldWrapper span[id*="lblFullName2"],
#ContentMiddle .imod_fieldWrapper span[id*='lblCCNoHypens'] {
  display: block;
  font-size: 0.8em;
  font-style: italic;
  opacity: 0.5;
}
#ContentMiddle .imod_fieldWrapper #divPayByCC .imod_fieldWrapper .imod_fieldInput {
  width: auto;
}
#ContentMiddle .imod_fieldWrapper #divPayByCC .imod_fieldWrapper .imod_fieldInput select,
#ContentMiddle .imod_fieldWrapper #divPayByCC .imod_fieldWrapper .imod_fieldInput input {
  width: auto;
}
#ContentMiddle fieldset {
  border: none;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] {
  display: block;
  font-size: 14px;
  line-height: normal;
  margin: 0 -10px;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody {
  display: block;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr {
  display: block;
  float: left;
  width: 50%;
  border: 10px solid #fff;
}
@media only screen and (max-width:  699px ) {
  #ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr {
    width: 100%;
    float: none;
    border: none;
  }
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:nth-child(3n+3) {
  clear: left;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr.active td,
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr.active td:hover {
  background: #000000;
  border-color: #041E42;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr td {
  padding: 0px;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}
@media only screen and (max-width:  699px ) {
  #ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr td {
    margin-bottom: 20px;
  }
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr td input[type='radio'] {
  display: none;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr td input[type='radio']:checked + label {
  background: #041E42;
  color: #fff;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr td input[type='radio']:checked + label:before {
  content: "\f05d";
  color: #fff;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr td label {
  display: flex;
  padding: 18px 80px 18px 18px;
  color: #000;
  font-weight: bold;
  line-height: 1.5em;
  border: 1px solid #041E42;
  border-radius: 4px;
  background: #F4F4F4;
  cursor: pointer;
  transition: all 250ms ease;
  width: 100%;
  height: 100%;
  align-items: center;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr td label:before {
  color: #939393;
  display: block;
  font-family: "FontAwesome";
  font-size: 20px;
  line-height: 20px;
  padding: 0 0px;
  content: "\f1db";
  width: 20px;
  text-align: center;
  height: 20px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 5px;
  left: auto;
  right: 5px;
  font-weight: normal;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr td label:hover {
  background: #041E42;
  color: #fff;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr td label:hover:before {
  color: #fff;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child {
  display: block;
  float: left;
  width: 100%;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td {
  text-align: center;
  display: block;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td label {
  display: block;
  text-align: center;
  background: none;
  border: 2px dashed #63666A;
  color: #63666A;
  border-radius: 0px;
  padding: 15px 20px;
  font-size: 1.5em;
  font-weight: normal;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td label:before {
  content: "";
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td label span {
  position: relative;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td label span:before {
  content: "\f055";
  display: block;
  font-family: "FontAwesome";
  font-size: 20px;
  line-height: 20px;
  padding: 0 0px;
  width: 20px;
  text-align: center;
  height: 20px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 3px;
  left: -25px;
  font-weight: normal;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td label:hover {
  background: #63666A;
  border: 2px solid #63666A;
  color: #fff;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td label:hover span:before {
  color: #fff;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td input[type='radio'] {
  display: none;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td input[type='radio']:checked + label {
  background: #63666A;
  border: 2px solid #63666A;
  color: #fff;
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td input[type='radio']:checked + label:before {
  content: "";
}
#ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr:last-child td input[type='radio']:checked + label span:before {
  content: "\f055";
  color: #fff;
}
#ContentMiddle #ContentAmountBoxes .give-step {
  margin: 0 -15px;
}
@media only screen and (max-width: 699px) {
  #ContentMiddle #ContentAmountBoxes .give-step {
    margin: 0;
  }
}
#ContentMiddle #ContentAmountBoxes .give-step > ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
#ContentMiddle #ContentAmountBoxes .give-step > ul li {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding: 0 15px 15px;
  text-align: center;
  position: relative;
}
@media only screen and (min-width: 700px) and (max-width: 1023px) {
  #ContentMiddle #ContentAmountBoxes .give-step > ul li {
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
    padding: 0 15px 32px;
  }
}
@media only screen and (max-width: 699px) {
  #ContentMiddle #ContentAmountBoxes .give-step > ul li {
    display: block;
    width: 100%;
    padding: 0 0 10px;
  }
}
#ContentMiddle #ContentAmountBoxes .give-step > ul li a {
  color: #fff;
  background: #041E42;
  border: 2px solid #041E42;
  border-radius: 6px;
  display: block;
  text-align: center;
  padding: 25px 15px;
  text-decoration: none;
  font-size: 1.65em;
}
#ContentMiddle #ContentAmountBoxes .give-step > ul li a:hover {
  background: #fff;
  color: #041E42;
  border-color: #041E42;
}
#ContentMiddle #ContentAmountBoxes .give-step > ul li a.selected {
  background: #fff;
  color: #041E42;
  border-color: #041E42;
}
#ContentMiddle .designation-section .divAdvancedDesignations {
  width: 100%;
  margin: 0;
  border: 2px solid #c8c8c8;
}
#ContentMiddle .designation-section .divAdvancedDesignations .fa {
  color: #041E42;
}
#ContentMiddle .login-password-box {
  width: 100%;
}
#ContentMiddle #divButtons {
  clear: both;
  text-align: center;
}
.fancybox-overlay .fancybox-wrap {
  width: 600px!important;
  max-width: 90%;
}
.fancybox-overlay .fancybox-wrap .fancybox-inner {
  width: 100%!important;
}
.fancybox-overlay .fancybox-wrap .fancybox-skin {
  padding: 30px!important;
}
@media only screen and (max-width:  699px ) {
  .fancybox-overlay .fancybox-wrap .fancybox-skin {
    padding: 15px!important;
  }
}
.fancybox-overlay .fancybox-wrap div.imod_designations_wrapper {
  max-width: 100%;
}
.fancybox-overlay .fancybox-wrap div.imod_designations_wrapper br {
  display: none;
}
.fancybox-overlay .fancybox-wrap div.imod_designations_wrapper span.instruction-section {
  font-size: 1em;
  width: 100%;
  margin: 0 0 0px;
}
.fancybox-overlay .fancybox-wrap div.imod_designations_wrapper div.notification-section-top {
  margin-top: 10px;
}
.fancybox-overlay .fancybox-wrap div.header-section {
  float: left;
  min-height: 35px;
  padding: 0 0 15px;
  width: 100%;
}
.fancybox-overlay .fancybox-wrap div.header-section h1 {
  margin: 0;
  padding: 5px 220px 5px 0;
  font-size: 1.5em;
  min-height: 40px;
}
@media only screen and (max-width:  699px ) {
  .fancybox-overlay .fancybox-wrap div.header-section h1 {
    margin-top: 40px;
    font-size: 1.3em;
    padding-right: 0;
  }
}
.fancybox-overlay .fancybox-wrap div.header-section div.search-section {
  background: #ddd;
  border: 1px solid #ccc;
  margin: 0 !important;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
}
@media only screen and (max-width:  699px ) {
  .fancybox-overlay .fancybox-wrap div.header-section div.search-section {
    width: 100%;
  }
}
.fancybox-overlay .fancybox-wrap div.header-section div.search-section div.search-box {
  border: none;
  background: none;
}
.fancybox-overlay .fancybox-wrap div#main-section {
  background: #eee;
  border: 2px solid #ddd;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset {
  border: none;
  margin: 15px 0 0;
  padding: 8px 15px 10px;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset legend {
  font-size: 1.25em;
  line-height: 1.3em;
}
@media only screen and (max-width:  699px ) {
  .fancybox-overlay .fancybox-wrap div#main-section fieldset legend {
    font-size: 1em;
  }
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin: 0 0 5px;
  width: 100%;
  float: none;
  overflow: hidden;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section input {
  margin-top: 2px;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section label {
  display: block;
  max-width: 100%;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section div.arrow {
  position: relative;
  top: -3px;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section ul.sub-section {
  width: 100%;
  float: none;
  margin: 0;
  padding: 5px 0 0 17px;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section ul.sub-section li.child {
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 5px 15px 5px 0;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section ul.sub-section li.child input[type="checkbox"] {
  width: 15px;
  margin: 0;
}
.fancybox-overlay .fancybox-wrap div#main-section fieldset ul li.section ul.sub-section li.child label {
  float: none;
  padding-left: 23px;
  line-height: normal;
}
.fancybox-overlay .fancybox-wrap div.button-section {
  display: block;
  width: 100%;
  padding: 20px 0 0;
  float: left;
}
.fancybox-overlay .fancybox-wrap div.button-section input {
  background: #041E42;
  color: #fff;
  display: inline-block;
  float: right;
  padding: 10px 20px;
  border: none;
  max-width: 40%;
  transition: all 250ms ease;
}
.fancybox-overlay .fancybox-wrap div.button-section input:hover {
  background: #222;
  color: #fff;
}
.fancybox-overlay .fancybox-wrap div.button-section a {
  border: 1px solid #ccc;
  color: #333;
  margin: 0!important;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  float: left;
  max-width: 40%;
}
.fancybox-overlay .fancybox-wrap div.button-section a:hover {
  background: #222;
  color: #fff;
  text-decoration: none;
  border-color: #222;
}
.flexbox #ContentMiddle table[id*="rblAdvancedDesignations"] tbody {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width:  699px ) {
  .flexbox #ContentMiddle table[id*="rblAdvancedDesignations"] tbody {
    display: block;
  }
}
.flexbox #ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr {
  display: inherit;
  flex-direction: column;
}
.flexbox #ContentMiddle table[id*="rblAdvancedDesignations"] tbody tr td {
  flex: 1 0 auto;
}
/* GIVE BUTTONS 1 STYLING 
---------------------------------------------*/
ul.giveBtnsList {
  margin: 0 -10px;
  padding: 0;
  list-style: none;
  text-align: center;
}
ul.giveBtnsList li {
  display: inline-block;
  padding: 0 10px;
  margin-right: -4px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  ul.giveBtnsList li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width:  699px ) {
  ul.giveBtnsList li {
    padding: 0 5px;
    width: 50%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
ul.giveBtnsList li a {
  text-decoration: none;
  display: block;
  font-size: 2em;
  line-height: 1em;
  padding: 20px 30px;
  background: #041E42;
  color: #fff!important;
  font-weight: bold;
}
ul.giveBtnsList li a.otherAmt {
  font-weight: normal;
  color: #fff;
}
ul.giveBtnsList li a:hover {
  background: #222;
  color: #fff!important;
  border-color: #000;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  ul.giveBtnsList li a {
    font-size: 26px;
    padding: 15px 20px;
  }
}
@media only screen and (max-width:  699px ) {
  ul.giveBtnsList li a {
    font-size: 24px;
    padding: 13px;
  }
}
ul.giveBtnsList span.sm {
  float: left;
  font-size: 0.55em;
  margin-right: 2px;
  position: relative;
  top: -5px;
}
@media only screen and (max-width:  699px ) {
  ul.giveBtnsList span.sm {
    float: none;
  }
}
/* GIVE BUTTONS 2 STYLING 
---------------------------------------------*/
.giveInputWrapper {
  display: block;
  position: relative;
  height: 50px;
  padding-right: 20px;
  max-width: 50%;
  margin: 0 auto;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .giveInputWrapper {
    max-width: 80%;
  }
}
@media only screen and (max-width:  699px ) {
  .giveInputWrapper {
    max-width: 100%;
  }
}
.giveInputWrapper .giveInputInner {
  background: #fff;
  overflow: hidden;
  padding-left: 45px;
  position: relative;
  border: 2px solid #ccc;
}
.giveInputWrapper label {
  display: none;
}
.giveInputWrapper .dollarSign {
  position: absolute;
  top: 0;
  left: 0;
  color: #222 !important;
  font-size: 25px;
  line-height: 50px;
  width: 35px;
  text-align: right;
}
.giveInputWrapper #giveInput {
  background: none;
  border: none;
  height: 46px;
  color: #222 !important;
  width: 100%;
  font-size: 25px;
}
@media only screen and (max-width:  699px ) {
  .giveInputWrapper #giveInput {
    font-size: 18px;
  }
}
.giveInputWrapper #giveBtnSubmit {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  border: none;
  background: #222;
  padding: 0 20px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 250ms ease;
  line-height: 50px;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}
.giveInputWrapper #giveBtnSubmit:hover {
  background: #000;
}
@media only screen and (max-width:  699px ) {
  .giveInputWrapper #giveBtnSubmit {
    padding: 0 10px;
    font-size: 11px;
  }
}
.giveInputWrapper #errorMsg {
  font-style: italic;
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
}
.imod_fieldInput .floating-label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 22%;
  transition: 0.2s ease all;
  color: #63666A;
}
.imod_fieldInput.focused .floating-label {
  color: #00B5E2;
  font-size: 10px;
  top: -2px;
  left: 5px;
  font-size: 9px;
}
.imod_fieldInput.focusedVal .floating-label {
  color: #63666A;
  font-size: 10px;
  top: -2px;
  left: 5px;
  font-size: 9px;
}
